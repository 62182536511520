.ant-page-header-heading-extra {
  text-align: right;
}

.captcha > div > div {
  width: 100% !important;
}

.ant-card-meta-description {
  min-height: 50px;
}

#course-videos {
  height: 780px;
  overflow-y: scroll;
}

#show-list-mobile {
  float: right;
}

.ant-table-row:hover {
  cursor: pointer;
}

.form-links button {
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0;
  color: #0e326d;
}

.ant-btn-dangerous.ant-btn-primary {
  background: #0e326d !important;
  border-color: #0e326d !important;
}

.form-links {
  font-size: 12px;
}

.form-links button:focus {
  outline: none;
}

.form-links button:hover {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  #show-list-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #course-videos {
    display: none;
  }

  .hide-mob {
    display: none;
  }
}
